@media screen and (max-width: 600px) {
  .logo-img {
      margin-top: 50px;
  }
}

.payment-item-wrapper {
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  transition: .2s;
}

.payment-item-wrapper:hover {
  border: 1px solid #aaa;
}

.payment-item-left {
  align-items: center;
  display: flex;
}

.mb-12 {
  margin-bottom: 12px;
}

.icon-check {
  display: none;
  font-size: 21px;
  line-height: 10px;
}